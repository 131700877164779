import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import scrollToTop from "../../common/ScrollToTop";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const scrollUp = () => {
    const element = document.getElementById("intro") as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={4} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="tel:+393932999500" style={{textDecoration: 'underline'}}>
                {t(`Let's Chat`)}
              </a>
            </Col>
            <Col lg={4} md={10} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>Via Francesco Ciusa,</Para>
              <Para>09131 Cagliari (CA)</Para>
            </Col>
            <Col lg={4} md={8} sm={12} xs={12}>
              <Title>{t("Info")}</Title>
              <Large to="/" left="true">
                {t("VAT Number")}
              </Large>
              <Large left="true" to="/">
                {t("Public Register ID")}
              </Large>
            </Col>
            <Col lg={4} md={8} sm={12} xs={12}>
              <Title>{t("ScrollUp")}</Title>
              <Large left="true" onClick={() => scrollUp()}>
                {t("Get back to homepage")}
              </Large>
              <Large>
                ©2022, Annarita Cabras
              </Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
