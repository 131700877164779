import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import FloatingWhatsAppContent from "../content/FloatingWhatsApp.json";
import FloatingWhatsApp from "react-floating-whatsapp";

const Router = () => {
    return (
        <Suspense fallback={null}>
            <Styles />
            <Header />
            <Switch>
                {routes.map((routeItem) => {
                    return (
                        <Route
                            key={routeItem.component}
                            path={routeItem.path}
                            exact={routeItem.exact}
                            component={lazy(() => import(`../pages/${routeItem.component}`))}
                        />
                    );
                })}
            </Switch>
            <Footer />
            <FloatingWhatsApp
                styles={{ zIndex: 1000 }}
                phoneNumber={FloatingWhatsAppContent.phoneNumber}
                accountName={FloatingWhatsAppContent.accountName}
                chatMessage={FloatingWhatsAppContent.chatMessage}
                placeholder={FloatingWhatsAppContent.placeholder}
                avatar={require("../images/me.jpg")}
                allowClickAway
                notification
                notificationDelay={60000} // 1 minute
                notificationSound
            />
        </Suspense>
    );
};

export default Router;
