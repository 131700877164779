import styled from "styled-components";

export const StyledContainer = styled("div") <any>`
  position: relative;
  width: 100%;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};

  @media only screen and (max-width: 1024px) {
    // max-width: calc(100% - 68px);
  }

  @media only screen and (max-width: 768px) {
    // max-width: calc(100% - 38px);
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
  }
`;
